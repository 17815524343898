import React, { useCallback, useEffect, useState } from 'react'
import { Button, Grid, InputLabel, TextField, Typography, DialogActions } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ViewPermissionDialog from './ViewPermissionDialog';
import useApi from '../../../hooks/useApi';
import ApplicationLevel from './ApplicationLevel';
import { useSnackbar } from 'notistack';
import CustomTextField from '../../UiComponents/TextField/TextField';
import { LoadingIndicator, Modal } from '../../UiComponents';
import { compareAsc, format, isPast, isSameDay, isValid } from 'date-fns';
import { useApplicationContext } from '../../../contexts/ApplicationContext';
import CustomDatePicker from './CustomDatePicker';
import { ReqMethod } from '../../../hooks/useApi/useApi';
const borderCss = "1px solid #464659";

const useStyles = makeStyles(() =>
  createStyles({
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: "borderCss",
      borderRadius: "2px",
      boxSizing: 'border-box',
    },
    spaceRight: {
      paddingRight: "10px",
    },
    appContainer: {
      padding: "1rem",
      background: "#2b2b36",
      marginTop: "1rem",
      maxHeight: "320px",
    },
    levelWrapper: {
      maxHeight: "210px",
      overflow: 'auto'
    },
    appTitle: {
      width: "100%",
    },
    labels: {
      color: "#fcfcfc !important",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    fieldRoot: {
      marginTop: "16px",
      outlineColor: '#fff'
    },
    field: {
      color: "#FCFCFC",
      fontSize: "14px",
      lineHeight: "16px",
      padding: "10px",
    },
    dialogRoot: {
      width: '50%',
      maxWidth: '1080px'
    },
    actionButtons: {
      margin: '20px 10px',
      justifyContent: "space-between",
      display: "flex",
      borderTop: "1px solid #33333F",
      paddingTop: "20px",
    },
    btnAddNUser: {
      backgroundColor: "#0089ff",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#0089ff",
      },
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    btnCancel: {
      borderRadius: "2px",
      border: "1px solid rgba(235,235,245,0.35)",
      color: "#0089FF",
      background: "none",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    dialogTitle: {
      color: '#fff',
    },
    colorWhite: {
      color: "#fff",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
    },
    vp: {
      backgroundColor: "#2B2B36",
      border: borderCss,
      borderRadius: "2px",
      color: "#FCFCFC",
      "&:hover": {
        backgroundColor: "#2B2B36",
      },
      fontSize: "14px",
      lineHeight: "16px",
      textTransform: "none",
      padding: "12px 0px",
      marginTop: "30px",
      width: "100%",
    },
  })
);
interface AppListType {
  application_id: number;
  application_name: string;
}
interface LevelType {
  level_id: number;
  level_name: string;
}
interface MapNewApplicationProps {
  handleSave: () => void;
  closeDialog: () => void;
  editData: any;
  email:string;
  userId:number;
}
interface level {
  level_id: number;
  level_name: string;
}
interface Selected {
  level_id: number[];
  level_name: string[];
}

function EditMappedApplication({ handleSave,email, userId, closeDialog, editData }: MapNewApplicationProps) {
  const [userRoles, setUserRoles] = useState([])
  const [applicationLevel, setApplicationLevel]: any = useState({ level_id: [], level_name: [] });
  const [levels, setLevels] = useState<LevelType[] | []>([]);
  const [permissionMap, setPermissionMap] = useState<any>({});
  const [selectedRole, setSelectedRole] = useState<number | null>(null);
  const [selectedRolePermission, setSelectedRolePermission]: any = useState(null);
  const [showPermissionDialog, setShowPermissionDialog] = useState(false);
  const [validFromDate, setValidFromDate] = useState<Date | null>();
  const [validToDate, setValidToDate] = useState<Date | null>();
  const [originalValues, setOriginalValues]: any = useState({});

  const classes = useStyles();
  const roleEndpoint = `/applications/${editData.application_id}/roles`
  const { enqueueSnackbar } = useSnackbar();
  const { applications }: any = useApplicationContext();

  const { data: rolesData,status:fetchRoleStatus, trigger: getUserRoles } = useApi(roleEndpoint, { method: "GET", deferred: true });
  const updateUserEndpoint = `/applications/${editData.application_id}/users/${userId}`;
  const method: ReqMethod = "PUT";
  const options = {
    method, deferred: true,
    includePMSCredentialsInRequestBody: true,
    onError: handleApiError,
    doNotExtractError: true
  }
  const { trigger: updateUser, data: saveApiResponse,
    status: saveStatus } = useApi(updateUserEndpoint, options);

    function handleApiError(error: any) {
      const defaultErrorMessage = "Error occurred while processing the request";
      if (!error?.response) {
        enqueueSnackbar(defaultErrorMessage, { variant: "error" });
        return;
      }
      const { status, data } = error?.response;
      if ((status === 400 || status === 401) && data.message) {
        enqueueSnackbar(data.message, { variant: "error" });
        return;
      }
      enqueueSnackbar(defaultErrorMessage, { variant: "error" });
    }

  const validateForm = () => {
    const levelId = applicationLevel?.level_id;
    if (!selectedRole) {
      enqueueSnackbar("Please select a role", { variant: "error" });
    }
    else if (!levelId || !Array.isArray(levelId) || levelId.length === 0) {
      enqueueSnackbar("Please select an application level", { variant: "error" });
    }
    else if (!isValid(validToDate) || (validToDate && isPast(validToDate))) {
      enqueueSnackbar("Please select a valid date", { variant: "error" });
    }
    else {
      const application_level_id = applicationLevel.level_id.map((id: number) => (id))
      const valid_from = validFromDate && format(new Date(new Date(validFromDate).setHours(0, 0, 0)), "y-MM-dd HH:mm:ss");
      const valid_to = validToDate && format(new Date(new Date(validToDate).setHours(23, 59, 59)), "y-MM-dd HH:mm:ss");
      const updatedObj = {
        user: {
          email_id: email,
          application_level_id,
          role_id: selectedRole,
          valid_from,
          valid_to,
        }
      };
      updateUser(updatedObj)
    }
  }
  const validateDate = (date: any, key: string) => {
    switch (key) {
      case 'validTo':
        if (date && validFromDate) {
          const isFuture = compareAsc(new Date(date), new Date(validFromDate));
          const isSameDate = isSameDay(new Date(date), new Date(validFromDate));
          if (isSameDate) {
            const errorMessage='Valid To date can not be same as Valid From';
            enqueueSnackbar(errorMessage, { variant: "error" });
            return;
          }
          else if (isFuture < 0) {
            const errorMessage = 'Valid To date can not be less than Valid From';
            enqueueSnackbar(errorMessage, { variant: "error" });
            return;
          }
          else {
            setValidToDate(date);
          }
        }
        break;
      case 'validFrom':
        if (date && validToDate) {
          const isFuture = compareAsc(new Date(date), new Date(validToDate));
          const isSameDate = isSameDay(new Date(date), new Date(validToDate));
          if (isSameDate) {
            const errorMessage = 'Valid From date can not be same as Valid To date';
            enqueueSnackbar(errorMessage, { variant: "error" });
            return;
          }
          else if (isFuture > 0) {
            const errorMessage = 'Valid From date can not be greater than Valid To';
            enqueueSnackbar(errorMessage, { variant: "error" });
            return;
          }
          else {
            setValidFromDate(date);
          }
        }
        break;
    }
  }

  const handleCloseDialog = () => {
    setShowPermissionDialog(false);
  }

  const handleChangeRole = ({ target: { value } }: any) => {
    setSelectedRole(value);
    setSelectedRolePermission(permissionMap[value]);
  }

  const isValuesChanged = () => {
    if (!Object.keys(originalValues).length || !isValid(validToDate) || (validToDate && isPast(validToDate))) return false;
    const { role_id, valid_to } = originalValues;
    const orginalLevel = originalValues.levels || [];
    const levelIds = applicationLevel?.level_id;
    const originValidTo = format(new Date(valid_to), 'dd/LL/yyyy');
    const stateValidTo = validToDate && format(new Date(validToDate), 'dd/LL/yyyy');
    const isLevelSame = orginalLevel.length === levelIds.length &&
      orginalLevel.every((val: number) => levelIds?.some((id: number) => id === val));
    const isRoleSame = role_id === selectedRole;
    const isValidToSame = stateValidTo === originValidTo;
    return !isRoleSame || !isLevelSame || !isValidToSame;
  }

  const setupAppLevelAndgetRolesData = useCallback(() => {
    const editDataAppId = editData.application_id
    const applicationLevel: level[] = editData.application_level;
    const selectedAppData = applications?.find(({ application_id }: any) => application_id === editDataAppId)
    const { valid_from, valid_to } = editData;
    if (valid_from && valid_to) {
      const from = new Date(valid_from);
      const to = new Date(valid_to);
      setValidFromDate(from);
      setValidToDate(to);
    }
    const appLevel: Selected = { level_id: [], level_name: [] };
    if (!applicationLevel) {
      enqueueSnackbar("Application level not available", { variant: "warning" });
    }
    applicationLevel?.forEach(({ level_id, level_name }) => {
      appLevel.level_id.push(level_id);
      appLevel.level_name.push(level_name)
    })
    setApplicationLevel(appLevel);
    setLevels(selectedAppData?.levels || [])
    getUserRoles();
  }, [editData.application_id])

  useEffect(() => {
    if ((saveApiResponse?.message || "").toLowerCase() === "success") {
      enqueueSnackbar("User Details Updated", { variant: "success" });
      handleSave();
      closeDialog();
    }
  }, [saveApiResponse, saveStatus.error]);

  useEffect(() => {
    if (applications && Array.isArray(applications) && !rolesData && !rolesData?.roles) {
      setupAppLevelAndgetRolesData();
      const { role_id, valid_to, application_level } = editData;
      const previousVal = {
        role_id,
        valid_to,
        levels: application_level?.map(({ level_id }: any) => level_id) || []
      }
      setOriginalValues(previousVal)
    }
  }, [editData.application_id, applications]);

  useEffect(()=>{
    if (!fetchRoleStatus.pending && fetchRoleStatus.error) {
      enqueueSnackbar("Fetching roles failed, please check your permission", { variant: "error" });
    }
  },[fetchRoleStatus.error])

  useEffect(() => {
    if (rolesData?.roles) {
      const { roles } = rolesData;
      const map:any = {}
      roles.forEach(({ role_id, permission_mapping }: any) => {
        map[role_id] = permission_mapping;
      });
      const selectedRoleId = editData.role_id
      setUserRoles(roles)
      setSelectedRole(selectedRoleId);
      setPermissionMap(map);
      setSelectedRolePermission(map[selectedRoleId])
    }
  }, [rolesData])

  return (
    <Modal title='Edit'
      contentWrapperStyle={{ border: 'none' }}
      open={true} onClose={closeDialog}>
      {(fetchRoleStatus.pending || saveStatus.pending) && <LoadingIndicator />}
      <form noValidate>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.spaceRight}>
              <CustomTextField
                name="selectApplication"
                label="Application *"
                readOnly
                style={{ margin: 0 }}
                value={editData?.application_name}
              />
            </Grid>
            <Grid item xs={8}>
              <div className={classes.fieldWrapper}>
                <InputLabel
                  classes={{ root: classes.labels }}
                  htmlFor="selectApplication-edit-map-app"
                >
                  Select Role
                </InputLabel>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  select
                  variant="outlined"
                  value={selectedRole}
                  classes={{ root: classes.fieldRoot }}
                  inputProps={{ className: classes.field }}
                  id="selectApplication-edit-map-app"
                  SelectProps={{
                    classes: {
                      iconOutlined: classes.colorWhite,
                    },
                  }}
                  onChange={handleChangeRole}
                >
                  {userRoles.map((option: any) => (
                    <option
                      className={classes.options}
                      key={option.role_id}
                      value={option.role_id}
                    >
                      {option.role_name}
                    </option>
                  ))}
                </TextField>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.fieldWrapper}>
                <Button
                  variant="outlined"
                  className={classes.vp}
                  onClick={() => setShowPermissionDialog(true)}
                >
                  View Permissions
                </Button>
              </div>
            </Grid>
            <Grid item xs={6} className={classes.spaceRight}>
              <CustomDatePicker
                value={validFromDate}
                change={validateDate}
                disabled
                label="Valid From"
                key="editValidFrom"
                field="validFrom" />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                value={validToDate}
                change={validateDate}
                disablePast
                label="Valid To"
                key="editValidTo"
                field="validTo" />
            </Grid>
            <Grid
              container
              className={classes.appContainer}
            >
              <div className={classes.appTitle}>
                <Typography className={classes.colorWhite}>
                  Application Levels *
                </Typography>
              </div>
              <Grid item xs={12} sm={12} container className={classes.levelWrapper}>
                <ApplicationLevel
                  selected={applicationLevel}
                  setSelected={setApplicationLevel}
                  levels={levels} />
              </Grid>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        <ViewPermissionDialog open={showPermissionDialog} close={handleCloseDialog} permissions={selectedRolePermission} />
      </form>
      <DialogActions>
        <Grid item xs={12}>
          <div className={classes.actionButtons}>
            <Button variant="outlined" className={classes.btnCancel}
              onClick={closeDialog} >
              Cancel
            </Button>
            <Button variant="contained"
              disabled={!isValuesChanged()}
              className={classes.btnAddNUser}
              onClick={validateForm} >
              Update
            </Button>
          </div>
        </Grid>
      </DialogActions>
    </Modal>

  )
}

export default EditMappedApplication