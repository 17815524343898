import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  WithStyles,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
    closeButton: {
      position: "absolute",
      right: "2px",
      top: "15px",
      color: "#FFFFFF",
      fontSize: "14px",
    },
  });
interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
const DialogTitle = withStyles(DialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "0px 20px",
    padding: 0,
    color: "#FFFFFF",
    scrollY: "auto",
    scrollx: "auto",
  },
}))(MuiDialogContent);

type TypePermissionDialogProp = {
  classes: { [key: string]: string };
  onClose: () => void;
  isOpen: boolean;
  permissionChecked: any;
  checkboxAvailable: any;
};
export default function PermissionDialog({
  classes,
  onClose,
  isOpen,
  permissionChecked,
  checkboxAvailable
}: TypePermissionDialogProp) {
  return (
    <Dialog
      classes={{ paper: classes.dialogRoot }}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Permissions
      </DialogTitle>
      <Grid container className="dcOuterPadding">
        <Grid item xs={6}>
          <Typography className="dcTitle dcHeader"></Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="dcFieldValue">Read</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="dcFieldValue">Write</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="dcFieldValue">Create/Delete</Typography>
        </Grid>
      </Grid>
      <DialogContent>
        {Array.isArray(permissionChecked) &&
          permissionChecked.map((app: any, index: any) => {
            const checkedPermissions = (app.permission && app.permission.split("|")) || [];
            const rolePermission = checkboxAvailable?.find((e: any) => e.component_code === app.component_code)
            const availablePermission = (rolePermission && rolePermission.possible_permissions.split("|")) || [];
            return (
              <>

                <Grid container className={classes.marginLeft15}>
                  <Grid container item xs={12}>
                    <Grid item xs={6}>
                      <Typography
                        className="dcFieldValue"
                      >
                        {app.component_desc}
                      </Typography>
                    </Grid>
                    {["read", "write", "delete"].map((i: any) => (
                      <Grid item xs={2}>
                        {availablePermission.includes(i) && (
                          <Checkbox
                            defaultChecked={checkedPermissions.includes(i)}
                            disabled
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                            className={classes.bgColorBlue}
                          />
                        )}
                        

                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <AccordionDetails
                  classes={{ root: classes.accordionRoot }}
                ></AccordionDetails>
              </>
            );
          })}
      </DialogContent>
    </Dialog>
  );
}
