import React from "react";
import type { ReactElement } from 'react';
import { Redirect } from "react-router";
import {IAppStore} from "../user/AppStore";
import {  useStore } from 'mobx-store-provider';

export default function ValidateRoute(props: { children: ReactElement }) {
    const { user }: IAppStore = useStore();
    let to = '/'
    if (window.location.href.includes('dashboard?code=')) {
        to = window.location.pathname + window.location.search;
    }

    if (user === null) {
        return <div> loading... </div>
    } else {
        if (user.isLoggedIn) {
            return props.children
        } else {
            return <Redirect to={to} />;
        }
    }
}

