import React, { useState, useEffect } from "react";
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { SnackbarProvider, useSnackbar } from "notistack";
import { API } from "../../api/property";
import ErrorDialog from "../../utils/ErrorDialog";
import callXhrRequest from "../../utils/xhrRequestHandler";
import { arrayOfErrorsRefresh, arrayOfErrorsLogout } from '../../utils/helper';
import { useStore } from "mobx-store-provider";
import { LoadingIndicator } from "../UiComponents";
const textFieldBorder ="1px solid #464659"
const CssTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      marginTop: "16px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: "30px",
    },
    noSpace: {
      padding: "0px",
      margin: "0px",
    },
    spaceTop: {
      marginTop: "35px",
    },
    space2Top: {
      marginTop: "20px",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    spaceLeft: {
      paddingLeft: "10px",
    },
    formTitle: {
      fontWeight: "bold",
      fontSize: "21px",
      color: "#FFFFFF",
      lineHeight: "25px",
    },
    form: {
      width: "100%", 
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    labels: {
      color: "#fcfcfc",
      fontSize: "16px",
      transform: "scale(1)",
    },
    accordionRoot: {
      color: "#FFFFFF",
      width: "100%",
      margin: "0px",
      padding: "0px",
      boxShadow: "none",
      "& .MuiAccordionSummary-content": {
        margin: "0px",
      },
      minHeight: "auto !important",
    },
    noSpaceNoMin: {
      margin: "0px !important",
      padding: "0px !important",
      minHeight: "auto !important",
    },
    typoRoot: {
      paddingLeft: "24px",
    },
    field: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
    },
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      boxSizing: "border-box",
    },
    icon: {
      color: "#FFFFFF",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
    },
    fieldRoot: {
      marginTop: "16px",
    },
    selectRoot: {
      padding: "24px",
    },
    datePickerRoot: {
      color: "#FFFFFF",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      "& .MuiIconButton-root, .MuiPickersDay-day, .MuiPickersCalendarHeader-dayLabel": {
        color: "#FFFFFF",
      },
      "& .MuiPickersDay-daySelected": {
        border: `${textFieldBorder}`,
      },
    },
    bottomSection: {
      justifyContent: "space-between",
      display: "flex",
      borderTop: "1px solid #33333F",
      paddingTop: "20px",
    },
    btnCancel: {
      borderRadius: "2px",
      border: "1px solid rgba(235,235,245,0.35)",
      color: "#0089FF",
      background: "none",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    btnAddNUser: {
      backgroundColor: "#0089ff",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#0089ff",
      },
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    vp: {
      backgroundColor: "#2B2B36",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      color: "#FCFCFC",
      "&:hover": {
        backgroundColor: "#2B2B36",
      },
      fontSize: "14px",
      lineHeight: "16px",
      textTransform: "none",
      padding: "12px 0px",
      marginTop: "34px",
      width: "100%",
    },
    dialogRoot: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      minWidth: "800px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcOuterPadding": {
        padding: "10px 20px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        margin: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: "1px solid rgba(235,235,245,0.35)",
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    bgColor: {
      background: "#2B2B36",
      margin: "1px 0",
      padding: "7px 0",
    },
    marginLeft15: {
      backgroundColor: "#2B2B36",
      borderBottom: `${textFieldBorder}`,
      color: "#FFFFFF",
    },
    bgColorBlue: {
      color: "#1976d2 !important",
    },
    listButton: {
      padding: "0px",
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
    dividerColor: {
      background: "#464659",
      margin: "1em",
    },
    helperText: {
      color: "#d32f2f !important",
      position: "absolute",
      right: 0,
    },
    paddingTop: {
      padding: "1.5rem 0 !important",
    },
    isExpand: {
      position: "relative",
      top: "1.25rem",
    },
    dcFieldValue: {
      margin: "10px !important",
    },
    colorWhite: {
      color: "#FFFFFF",
    },
  })
);

interface ComponentProps {
  onCancelUser: any;
  editItem: any;
  onCheckbox: any
}

const EditRoleWrapper: React.FC<ComponentProps> = (props: ComponentProps) => {
  const [isPageLoading, setIsPageLoading] = useState(false);
  const classes = useStyles();
  const { user } = useStore();

  interface sObj {
    pm_s_id: string;
  }
  interface tObj {
    pm_s_token: string;
  }
  let pmSId: sObj = {
    pm_s_id: "",
  };
  let pmST: tObj = {
    pm_s_token: "",
  };
  pmSId =
    sessionStorage.getItem("pm_s_id") || JSON.parse(JSON.stringify(pmSId));
  pmST =
    sessionStorage.getItem("pm_s_token") || JSON.parse(JSON.stringify(pmST));
  const { enqueueSnackbar } = useSnackbar();
  const [rolename, setRoleName] = useState("");
  const [desc, setRoleDesc] = useState("");
  const [role, setRole] = useState(0);
  const [appId, setAppId] = useState(0);
  const [editPermissionMap, setEditPermissionMap] = useState([] as any);
  const [app, setApp] = useState("");

  const handleCheckbox = (event: any, checked: boolean) => {
    const defaultRead = ["dashboard", "portal_applications"]
    const [name, perm] = event.target.name.split("#");
    if (perm === 'read' && defaultRead.includes(name)) return;
    const newPermission: any = editPermissionMap.map((editPermission: any) => {
      const permissionsList = ["read", "write", "delete"]
      if (editPermission.component_code === name && permissionsList.includes(perm)) {
        const {selected: permissions = []} = editPermission;
        const selected = checked
        ? [...permissions, perm]
        : permissions.filter((permission:string)=> permission!==perm);
        return { ...editPermission, selected };
      }
      return editPermission;
    });
    setEditPermissionMap(newPermission);
  };
  
  const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
  const saveUserAction = () => {
    setIsPageLoading(true);
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const ROLES_API = `${API["GETALLAPPLS"]}/${appId}/roles/${role}`;
    const permissionMapping = editPermissionMap.map((item: any) => {
      const permissions: any = [];
      if (item.selected.includes("read")) {
        permissions.push("read");
      }
      if (item.selected.includes("write")) {
        permissions.push("write");
      }
      if (item.selected.includes("delete")) {
        permissions.push("delete");
      }
      return {
        component_code: item.component_code,
        component_desc: item.component_desc,
        permission: permissions.join("|"),
      };
    });

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${accessToken.access_token}`,
        'Ocp-Apim-Subscription-Key': `${API_KEY}`,
        'Ocp-Apim-Trace': `true`
      },
      body: JSON.stringify({
        role: {
          role_name: rolename,
          role_desc: desc,
          permission_mapping: permissionMapping,
        },
        pm_s_token: pmST,
        pm_s_id: pmSId,
      }),
    };
    fetch(ROLES_API, requestOptions)
      .then(function (response:any) {
        response.json().then((response: any) => {
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Role changed.", { variant: "success" });
          setIsPageLoading(false);
          setTimeout(function () {
            props.onCancelUser();
          }, 500);
        } 
        else if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            callXhrRequest().then(function(data){
                sessionStorage.setItem(tokenKey, data);
                saveUserAction()
            }).catch(function(error){
              user.triggerLogout();
              console.log('error', error)
            })
          } catch (error) {
            setIsPageLoading(false);
            console.log(error);
          }
          return;
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            setIsPageLoading(false);
            console.log(error);
          }
          return;
        }
        else {
          setError(true);
          setIsPageLoading(false);
          setErrMsg("Error occured. Please try again after sometime.");
        }
      })
    })
      .catch(function (error) {
        setIsPageLoading(false);
        console.log(error)
      });
    
  };

  const validation = () => {
    const regexCheck = /^[0-9a-zA-Z_-]+$/;
    const regexCheckDesc = /^[0-9a-zA-Z_-\s]+$/;
    
    if (!rolename) {
      enqueueSnackbar("Please enter role name.", { variant: "error" });
    } else if (!(regexCheck.test(rolename) && rolename.length < 50)) {
      enqueueSnackbar('Invalid role name (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)', { variant: "error" });
    } else if (!desc) {
      enqueueSnackbar("Please enter description of role.", { variant: "error" });
    } else if (!(regexCheckDesc.test(desc) && desc.length < 50)) {
      enqueueSnackbar('Invalid description of role (only 0-9,A-Z,a-z,_,- allowed upto 50 characters).', { variant: "error" });
    }
    else {
      saveUserAction();
    }
  }
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const updateError = () => {
    setError(false);
    setErrMsg("");
  };
  const cancelUserAction = () => {
    setTimeout(function () {
      props.onCancelUser();
    }, 100);
  };

  useEffect(() => {
    setRoleName(props.editItem.role_name);
    setRoleDesc(props.editItem.role_desc);
    setRole(props.editItem.role_id);
    setAppId(props.editItem.application_id);
    setApp(props.editItem.application_name);
    const defaultRead = ["dashboard", "portal_applications"]
    if (Array.isArray(props.editItem.permission_mapping)) {
      const newPermission: any = props.editItem.permission_mapping.map(
        (e: any) => {
          const permission = e.permission.split("|")?.filter((perm:string)=>perm!== "");
          const disabledRead = defaultRead.includes(e.component_code);
          const selectedVals = disabledRead? ["read", ...permission] : [...permission];
          const selected = Array.from(new Set([...selectedVals])) //to remove the duplicate values if any
          return { ...e, selected, disabledRead };
        }
      );
      setEditPermissionMap(newPermission);
    }
  }, [props.editItem]);

  return (
    <div className={classes.root}>
      {isPageLoading && <LoadingIndicator />}
      <List
        dense={true}
        classes={{ root: classes.noSpace }}
        className={classes.spaceTop}
      >
        <ListItem classes={{ root: classes.noSpace }}>
          <ListItemText
            classes={{ root: classes.noSpace }}
            primary="Basic Information"
            primaryTypographyProps={{
              style: { color: "#FFFFFF", fontWeight: "bold", fontSize: "16px" },
            }}
            secondary="All fields are mandatory"
            secondaryTypographyProps={{
              style: { color: "#FCFCFC", fontSize: "12px", marginTop: "10px" },
            }}
          />
        </ListItem>
      </List>
      <form className={classes.form} noValidate method="post">
        <Grid container>
          <Grid item xs={6} className={classes.spaceRight}>
            <CssTextField
              fullWidth
              name="rolename"
              label="Role Name"
              type="text"
              id="rolename"
              className={classes.fieldWrapper}
              InputProps={{
                disableUnderline: true,
                onChange: (e) => setRoleName(e.target.value),
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.labels,
              }}
              value={rolename}
            />
          </Grid>
          <Grid item xs={6} className={classes.spaceRight}>
            <div className={classes.fieldWrapper}>
              <InputLabel
                classes={{ root: classes.labels }}
                htmlFor="selectApplication"
              >
                Application
              </InputLabel>
              <TextField
                className={classes.textfield}
                fullWidth
                variant="outlined"
                classes={{ root: classes.fieldRoot }}
                inputProps={{ className: classes.field }}
                value={app}
                id="selectApplication"
                SelectProps={{
                  classes: {
                    iconOutlined: classes.icon,
                  },
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} className={classes.spaceRight}>
            <CssTextField
              fullWidth
              name="desc"
              label="Description"
              type="text"
              id="desc"
              className={classes.fieldWrapper}
              InputProps={{
                disableUnderline: true,
                onChange: (e) => setRoleDesc(e.target.value),
              }}
              style={{ height: "83px" }}
              InputLabelProps={{
                shrink: true,
                className: classes.labels,
              }}
              value={desc}
            />
          </Grid>
        </Grid>

        <Grid container className={classes.paddingTop}>
          <Grid
            container
            spacing={0}
            className="dcOuterPadding"
            style={{ paddingBottom: "1rem" }}
          >
            <Grid item xs={6}>
              <Typography className={classes.colorWhite}>
                Permissions
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.colorWhite}>Read</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.colorWhite}>Write</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.colorWhite}>Create/Delete</Typography>
            </Grid>
          </Grid>
          {Array.isArray(editPermissionMap) &&
            editPermissionMap.map((role: any, index: number) => {
              const checkedPermissions =
                (role.permission && role.permission.split("|")) || [];
              const rolePermission = props.onCheckbox?.find((e: any) => e.component_code === role.component_code)
              const availablePermission = (rolePermission && rolePermission.possible_permissions.split("|")) || [];

              return (
                <>
                  <Grid container spacing={0} className={classes.marginLeft15}>
                    <Grid container item xs={12} spacing={3}>
                      <Grid item xs={6}>
                        <Typography className={classes.dcFieldValue}>
                          {role.component_desc}
                        </Typography>
                      </Grid>
                      {["read", "write", "delete"].map((i: any) => {
                        return (
                          <Grid item xs={2}>
                            {availablePermission.includes(i) && (
                              <Checkbox
                                checked={role.selected.includes(i)}
                                disabled={i === "read" && role.disabledRead}
                                color="primary"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                                className={classes.bgColorBlue}
                                onChange={handleCheckbox}

                                name={`${role.component_code}#${i}`}
                              />
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </>
              );
            })}
        </Grid>
        <div className={`${classes.space2Top} ${classes.bottomSection}`}>
          <Button
            variant="outlined"
            className={classes.btnCancel}
            onClick={cancelUserAction}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.btnAddNUser}
            onClick={validation}
          >
            Save Role
          </Button>
        </div>
      </form>
      {error && (
        <ErrorDialog open={error} message={errMsg} updateError={updateError} />
      )}
    </div>
  );
};

interface ChildComponentProps {
  onCancelUser: any;
  editItem: any;
  onCheckbox: any
}

export default function EditRole({
  onCancelUser,
  editItem,
  onCheckbox
}: ChildComponentProps) {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      <EditRoleWrapper
        editItem={editItem}
        onCancelUser={onCancelUser}
        onCheckbox={onCheckbox}
      />
    </SnackbarProvider>
  );
}
