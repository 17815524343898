import React, { useEffect, useState } from 'react'
import { Button, Grid, Typography, IconButton } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CustomTextField, DataTable, LoadingIndicator } from '../UiComponents';
import { getManageAppGridCol } from './ColumnSchema';
import { format } from "date-fns";
import EditMappedApplication from './UsersComponent/EditMappedApplication';
import EditIcon from "@material-ui/icons/Edit";
import { useLocation, useHistory } from "react-router-dom";
import BreadcumbComponent from '../../utils/breadcurmb';
import useApi from '../../hooks/useApi';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      margin: '20px',
    },
    label: {
      color: '#b3b3b3',
    },
    actionButtons: {
      margin: '20px 10px',
      justifyContent: "space-between",
      display: "flex",
      borderTop: "1px solid #33333F",
      paddingTop: "20px",
    },
    actionIcon:{
      color:'#fff'
    },
    btnCancel: {
      borderRadius: "2px",
      border: "1px solid rgba(235,235,245,0.35)",
      color: "#0089FF",
      background: "none",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  })
);

function ManageApplication({ editItem, onEditUser }: any) {
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [myApplications, setMyApplications]: any = useState([]);
  const [editData, setEditData]: any = useState({})

  const classes = useStyles();
  const { email_id, application_id, user_id } = editItem;
  const endpoint = `/applications/${application_id}/users/${user_id}`
  const { data: userDetails, status:userAPIStatus, trigger: getUserDetails } = useApi(endpoint, { method: "GET", deferred: true });

  const gridLabel = `Mapped Applications`;

  const handleUpdateApplication = () => {
    getUserDetails();
    setOpenEditDialog(false);
  }

  useEffect(() => {
    if (application_id && user_id) {
      getUserDetails();
    }
  }, [application_id, user_id])

  useEffect(() => {
    if (userDetails && Object.keys(userDetails).length) {
      const { application_mapping } = userDetails;
      const myApp: any = [...application_mapping];
      setMyApplications(myApp);
    }
  }, [userDetails])

  const handleDialogClose = () => {
    setOpenEditDialog(false);
  }
  const handleDialogOpen = (record: any) => {
    setOpenEditDialog(true);
    setEditData(record);
  }

  const templateHandler = (data: any, key: string) => {
    const { record } = data;
    switch (key) {
      case "edit":
        return (
          <IconButton className={classes.actionIcon} onClick={() => handleDialogOpen(record)}>
            <EditIcon
              fontSize="small"
            ></EditIcon>
          </IconButton>
        )
      case 'valid_to':
        if (!record[key] || record[key] === "") {
          return null;
        }
        // const dateStr = format(new Date(record[key]), "do MMM, yyyy")
        const dateStr = format(new Date(record[key]), "yyyy-MM-dd HH:mm:ss")
        return (<p>{dateStr}</p>)
      default:
        return <p>{record[key]}</p>
    }
  }

  return (
    <div className={classes.wrapper}>
      {userAPIStatus.pending && <LoadingIndicator />}
      <Grid container spacing={3}>
        <Grid item xs={10} md={6}>
          <CustomTextField
            name="emailAddress"
            label="Email Address "
            readOnly
            style={{ margin: 0 }}
            value={email_id} />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.label} variant="body1" component="p" >
            {gridLabel}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DataTable
            noWrapper
            refreshGrid={() => true}
            columns={getManageAppGridCol(templateHandler)}
            values={myApplications} />
        </Grid>

        <Grid item xs={12}>
          <div className={classes.actionButtons}>
            <Button
              variant="outlined"
              className={classes.btnCancel}
              onClick={e => onEditUser()}
            >
              Back
            </Button>
          </div>
        </Grid>
      </Grid>
      {
        openEditDialog && <EditMappedApplication
          key={editData?.application_id}
          handleSave={handleUpdateApplication}
          email={email_id}
          userId={user_id}
          editData={editData}
          closeDialog={handleDialogClose} />
      }
    </div>
  )
}


const ManageApplicatonWrapper = () => {
  const history = useHistory();
  const { state: routerState } = useLocation();
  const goToUserGrid = () => history.push("/");
  return (
    <>
    {/*TODO: This is repeated in AddUser. Create a component */}
      <div style={{
        display:'flex',
        alignItems: 'center',
        padding: '10px',
        margin:'0 2em',
        background:'#2B2B36'
        }}
        >
          <BreadcumbComponent route="addUser" func={goToUserGrid} />
        </div>
      <ManageApplication
        editItem={routerState}
        onEditUser={goToUserGrid}
        />
    </>
  );
};

export default ManageApplicatonWrapper;
