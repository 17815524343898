import { Checkbox, Grid, Typography, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react'
import { Modal } from '../../UiComponents';
const useStyles = makeStyles(() =>
  createStyles({
    bgColorBlue: {
      color: "#1976d2 !important",
    }
  })
);
function ViewPermissionDialog({ permissions, open, close }: any) {
  const classes = useStyles();
  const permissionLabelsReverse = ["Delete", "Write", "Read"];
  const permissionLabels = ["read", "write", "delete"]
  const onlyReadPermissions = ["dashboard", "audit"]
  return (
    <Modal
      contentWrapperStyle={{ minWidth: 600, padding: 25 }}
      title='Permissions' open={open} onClose={close}>
      <Grid container direction='row-reverse' spacing={0}>
        {
          permissionLabelsReverse.map((label) => (
            <Grid key={label} item xs={2}>
              <Typography className="dcFieldValue">{label}</Typography>
            </Grid>
          ))
        }
      </Grid>
      {Array.isArray(permissions) &&
        permissions.map((app: any, index: number) => {
          const permissions = app?.permission?.split("|") || [];
          return (
            <Grid container key={app?.component_desc + index}>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography className="dcFieldValue" >
                    {app.component_desc}
                  </Typography>
                </Grid>
                {permissionLabels.map((permission: any) => {
                  if (onlyReadPermissions.includes(app.component_code) && permission !== "read") return;
                  return (
                    <Grid item xs={2}>
                      <Checkbox
                        defaultChecked={permissions.includes(permission)}
                        disabled
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        className={classes.bgColorBlue}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          );
        })}
    </Modal>
  )
}

export default ViewPermissionDialog