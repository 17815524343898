import React from 'react';
import { Visibility } from "@material-ui/icons";
import { OPERATION_STATUS, OPERATION_STATUS_LABEL, OperationStatus, STATUS_TYPES } from "../../utils/constant";
import { convertUTCDateToLocal } from "../Events/utils/formatDateTime";
import { InputTypes } from "../UiComponents/MultiFilter/types";
import ViewAuditLog from './AuditLogComponents/ViewAuditLog';
import { GridContextMenuColumn } from "../UiComponents";


const templateHandler = (data: any, key: string) => {
  const { record }: { record: any } = data;
  switch (key) {
    case 'activity_datetime':
      if (!record[key] || record[key] === "") {
        return null;
      }
      return (<p>{convertUTCDateToLocal(record[key])}</p>)
    case "operation":
      const operation: OperationStatus = record[key];
      if (!operation) {
        return null;
      }
      return <p>{OPERATION_STATUS_LABEL[operation]}</p>
    case 'status':
      if (record[key].toLowerCase() === "success") {
        return (
          <p style={{ color: 'green' }}>{record[key]}</p>
        )
      }
      else if (record[key].toLowerCase() === "failure") {
        return (
          <p style={{ color: 'red' }}>{record[key]}</p>
        )
      }
      return <p>{record[key]}</p>
    case 'action':
      return <GridContextMenuColumn menuItems={
        [{
          id: "viewDetails",
          icon: Visibility,
          label: "View Details",
          component: <ViewAuditLog />
        }]} {...data} />
    default:
      return <p>{record[key]}</p>
  }
}

export const getAuditLogColumns = () => {
  return [
    {
      id: "operation",
      label: "Operation",
      searchable: true,
      sortable: true,
      exportable: true,
      template: (row: any) => templateHandler(row, "operation"),
      input: {
        type: "dropDown" as InputTypes,
        dropDownOptions: OPERATION_STATUS,
      },
      resolver: ({ operation }: { operation: string }) => OPERATION_STATUS_LABEL[operation]
    },
    {
      id: "activity_datetime",
      label: "Activity Date Time",
      template: (row: any) => templateHandler(row, "activity_datetime"),
      sortable: true,
      exportable: true,
      resolver: ({ activity_datetime }: any) => convertUTCDateToLocal(activity_datetime)
    },
    {
      id: "status",
      label: "Status",
      searchable: true,
      sortable: true,
      input: {
        type: "dropDown" as InputTypes,
        dropDownOptions: STATUS_TYPES,
      },
      template: (row: any) => templateHandler(row, "status"),
      exportable: true
    },
    {
      id: "requested_by",
      sortable: true,
      searchable: true,
      label: "Requested By",
      exportable: true
    },
    {
      id: 'metadata',
      label: 'Data',
      exportable: true,
      isHidden: true,
      resolver: ({ metadata }: any) => {
        if (typeof metadata !== "string") {
          return JSON.stringify(metadata);
        }
      }
    },
    {
      id: "action",
      label: "",
      template: (row: any) => templateHandler(row, "action"),
    },
  ];
};
